var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white",
    class: {
      'login-signin-on': _vm.state == 'signin'
    },
    attrs: {
      "id": "kt_login"
    }
  }, [_c('div', {
    staticClass: "login-aside d-flex flex-column flex-row-auto",
    staticStyle: {
      "background": "linear-gradient(to top, #4286f4, #373b44)"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  })]), _c('div', {
    staticClass: "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
  }, [_c('div', {
    staticClass: "d-flex flex-column-fluid flex-center"
  }, [_c('div', {
    staticClass: "login-form login-signin"
  }, [_c('form', {
    staticClass: "form",
    attrs: {
      "id": "kt_login_signin_form",
      "novalidate": "novalidate"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.changePassword.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "pb-13 pt-lg-0 pt-5"
  }, [_c('h3', {
    staticClass: "font-weight-bolder font-size-h4 font-size-h1-lg text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t('Type your new password')) + " ")])]), _c('validation-provider', {
    ref: "password",
    attrs: {
      "name": "new password",
      "vid": "new_password",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors,
          valid = _ref.valid,
          dirty = _ref.dirty;
        return [_c('b-form-group', [_c('label', {
          staticClass: "font-size-h6 font-weight-bolder text-primary pt-5"
        }, [_vm._v(_vm._s(_vm.$t('Password')))]), _c('b-input-group', {
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('b-button', {
                staticClass: "eye-btn",
                attrs: {
                  "tabindex": "-1"
                }
              }, [_c('b-icon', {
                staticClass: "h2 m-auto",
                attrs: {
                  "icon": "eye-fill"
                },
                on: {
                  "click": function click($event) {
                    return _vm.togglePwd('new');
                  }
                }
              })], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          ref: "new",
          staticClass: "form-control form-control-solid h-auto py-4 px-6 py-7 rounded-lg form-input",
          attrs: {
            "state": dirty ? valid : null,
            "type": _vm.newField ? 'password' : 'text'
          },
          model: {
            value: _vm.form.new_password,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "new_password", $$v);
            },
            expression: "form.new_password"
          }
        })], 1), _c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !!errors[0],
            expression: "!!errors[0]"
          }],
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !errors[0],
            expression: "!errors[0]"
          }],
          staticClass: "py-3"
        })], 1)];
      }
    }])
  }), _c('validation-provider', {
    attrs: {
      "name": "password confirmation",
      "vid": "confirm_password",
      "rules": "required|confirmed:new_password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors,
          valid = _ref2.valid,
          dirty = _ref2.dirty;
        return [_c('b-form-group', [_c('label', {
          staticClass: "font-size-h6 font-weight-bolder text-primary pt-5"
        }, [_vm._v(_vm._s(_vm.$t('Confirm Password')))]), _c('b-input-group', {
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('b-button', {
                staticClass: "eye-btn",
                attrs: {
                  "tabindex": "-1"
                },
                on: {
                  "click": function click($event) {
                    return _vm.togglePwd('confirm');
                  }
                }
              }, [_c('b-icon', {
                staticClass: "h2 m-auto",
                attrs: {
                  "icon": "eye-fill"
                }
              })], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          ref: "confirm",
          staticClass: "form-control form-control-solid h-auto py-4 px-6 py-7 rounded-lg form-input",
          attrs: {
            "state": dirty ? valid : null,
            "type": _vm.confirmField ? 'password' : 'text'
          },
          model: {
            value: _vm.form.confirm_password,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "confirm_password", $$v);
            },
            expression: "form.confirm_password"
          }
        })], 1), _c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !!errors[0],
            expression: "!!errors[0]"
          }],
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !errors[0],
            expression: "!errors[0]"
          }],
          staticClass: "py-3"
        })], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "pb-lg-0 pb-5"
  }, [_c('button', {
    staticClass: "btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3",
    class: {
      'cursor-disabled': _vm.pwdCheck
    },
    attrs: {
      "disabled": _vm.pwdCheck
    }
  }, [_c('i', {
    staticClass: "fas fa-sign-in-alt"
  }), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")])])], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
  }, [_c('a', {
    staticClass: "text-primary ml-10 font-weight-bold font-size-h5",
    attrs: {
      "href": "https://arlettipartners.com/contattaci"
    }
  }, [_vm._v(_vm._s(_vm.$t("Contact Us")))]), _c('router-link', {
    staticClass: "text-primary ml-10 font-weight-bold font-size-h5",
    attrs: {
      "to": {
        name: 'privacy-policy'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Privacy Policy")))]), _c('router-link', {
    staticClass: "text-primary ml-10 font-weight-bold font-size-h5",
    attrs: {
      "to": {
        name: 'cookies-policy'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cookie Policy")))])], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column-auto flex-column pt-lg-40 pt-15"
  }, [_c('a', {
    staticClass: "text-center mb-10",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-w-240",
    attrs: {
      "src": "media/logos/atlasposting-mini.png",
      "alt": ""
    }
  })])]);

}]

export { render, staticRenderFns }