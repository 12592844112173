<template>
  <div class="d-flex flex-column flex-root">
    <div id="kt_login" class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white" :class="{
      'login-signin-on': state == 'signin',
    }">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto"
        style=" background: #373b44; background: -webkit-linear-gradient(to top, #4286f4, #373b44); background: linear-gradient(to top, #4286f4, #373b44);">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/atlasposting-mini.png" class="max-w-240" alt="" />
          </a>
        </div>
        <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
      </div>
      <!--end::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form id="kt_login_signin_form" class="form" novalidate="novalidate" @submit.prevent="changePassword">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder font-size-h4 font-size-h1-lg text-primary">
                  {{ $t('Type your new password') }}
                </h3>
              </div>
              <validation-provider ref="password" v-slot="{ errors, valid, dirty }" name="new password" vid="new_password"
                rules="required">
                <b-form-group>
                  <label class="font-size-h6 font-weight-bolder text-primary pt-5">{{ $t('Password') }}</label>
                  <b-input-group>
                    <b-form-input ref="new" v-model="form.new_password" :state="dirty ? valid : null"
                      class="form-control form-control-solid h-auto py-4 px-6 py-7 rounded-lg form-input"
                      :type="newField ? 'password' : 'text'"></b-form-input>

                    <template #append>
                      <b-button class="eye-btn" tabindex="-1"><b-icon icon="eye-fill" class="h2 m-auto"
                          @click="togglePwd('new')"></b-icon></b-button>
                    </template>
                  </b-input-group>
                  <span v-show="!!errors[0]" class="text-danger">{{ errors[0] }}</span>
                  <div v-show="!errors[0]" class="py-3"></div>
                </b-form-group>
              </validation-provider>

              <validation-provider v-slot="{ errors, valid, dirty }" name="password confirmation" vid="confirm_password"
                rules="required|confirmed:new_password">
                <b-form-group>
                  <label class="font-size-h6 font-weight-bolder text-primary pt-5">{{ $t('Confirm Password') }}</label>
                  <b-input-group>
                    <b-form-input ref="confirm" v-model="form.confirm_password" :state="dirty ? valid : null"
                      class="form-control form-control-solid h-auto py-4 px-6 py-7 rounded-lg form-input"
                      :type="confirmField ? 'password' : 'text'"></b-form-input>

                    <template #append>
                      <b-button class="eye-btn" tabindex="-1" @click="togglePwd('confirm')"><b-icon icon="eye-fill"
                          class="h2 m-auto"></b-icon></b-button>
                    </template>
                  </b-input-group>
                  <span v-show="!!errors[0]" class="text-danger">{{ errors[0] }}</span>
                  <div v-show="!errors[0]" class="py-3"></div>
                </b-form-group>
              </validation-provider>

              <div class="pb-lg-0 pb-5">
                <button :disabled="pwdCheck" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  :class="{ 'cursor-disabled': pwdCheck }">
                  <i class="fas fa-sign-in-alt"></i>
                  {{ $t('Submit') }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
          <a href="https://arlettipartners.com/contattaci" class="text-primary ml-10 font-weight-bold font-size-h5">{{
            $t("Contact Us") }}</a>
          <router-link :to="{ name: 'privacy-policy' }" class="text-primary ml-10 font-weight-bold font-size-h5">{{
            $t("Privacy Policy") }}</router-link>
          <router-link :to="{ name: 'cookies-policy' }" class="text-primary ml-10 font-weight-bold font-size-h5">{{
            $t("Cookie Policy") }}</router-link>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import AuthService from "@/core/services/auth.service";
import i18n from "@/core/plugins/vue-i18n";
import {validationErrorSwal, backendErrorSwal} from "@/core/helpers/swal";

export default {
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        new_password: "",
        confirm_password: "",
      },
      newField: true,
      confirmField: true,
    };
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual.png";
    },
    pwdCheck() {
      return (
        this.form.new_password !== this.form.confirm_password || !this.form.new_password || !this.form.confirm_password
      );
    },
    token() {
      return this.$route.query.token;
    },
  },
  mounted() {
    this.verifyToken();
  },
  methods: {
    togglePwd(ref) {
      let input = ref === "confirm" ? "confirmField" : "newField";
      this[input] = !this[input];
    },
    async changePassword() {
      const data = {
        password: this.form.new_password,
        token: this.token,
      };
      await AuthService.pwdChange(data)
        .then(() => {
          Swal.fire({
            title: "",
            text: "Password changed correctly!, you can now login.",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then(() => {
            this.$router.push("/");
          });
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            validationErrorSwal(i18n.t("Token not valid"), i18n.t("The token provided is either invalid or has expired. Please request a new password reset link by clicking on 'Forgot password?'")).then(
              (result) => {
                if (result.isConfirmed || result.isDismissed) {
                  this.$router.push("login");
                }
              }
            );
          } else if (err.response?.status === 400) {
            const out = [];
            const errorList = err.response.data
            for (const key in errorList) {
              console.log(key, errorList[key])
              if (this.$refs[key]) {
                this.$refs[key].applyResult({
                  errors: errorList[key],
                  failedRules: {},
                  valid: false
                });
              }
              for (const err in errorList[key]) {
                out.push(errorList[key][err])
              }
            }
            Swal.fire({
              title: "",
              html: out.join('<br/>'),
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
          else {
            backendErrorSwal(
              i18n.t("Error"),
              i18n.t("An error occurred while changing the password. Please try again later.")
            );
          }
        });
    },
    async verifyToken() {
      await AuthService.verifyPasswordResetToken(this.token)
        .then(() => {
          return;
        })
        .catch(() => {
          validationErrorSwal(i18n.t("Token not valid"), i18n.t("The token provided is either invalid or has expired. Please request a new password reset link by clicking on 'Forgot password?'")).then(
            (result) => {
              if (result.isConfirmed || result.isDismissed) {
                this.$router.push("login");
              }
            }
          );
        });
    },
  },
};
</script>

<style>
.max-w-240 {
  max-width: 300px;
}

.show-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f3f6f9;
  border-bottom-right-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important;
}

.pwd-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>

<style lang="scss" scoped>
.eye-btn {
  background-color: #f3f6f9;
  border: none;
}

.eye-btn:hover,
.eye-btn:focus {
  background-color: #f3f6f9 !important;
  border-bottom-right-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important
}

.form-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cursor-disabled {
  cursor: not-allowed;
}
</style>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
